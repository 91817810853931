const glideUsersDomain = 'https://usr.sandbox-dmg.gcpp.io/';
const glideComposerDomain = 'https://cmp.sandbox-dmg.gcpp.io/';
const glideMediaDomain = 'https://mda.sandbox-dmg.gcpp.io/v1/';
const glideMediaCDN = 'https://media.sandbox-dmg.gcpp.io/';
const glideAuthDomain = 'https://ath.sandbox-dmg.gcpp.io/';
const glideTransmitDomain = 'https://tsm.sandbox-dmg.gcpp.io/';
const env_notes = '';
const tag_version = '';
// by default we'll hide the generic SSO login part of the login form
// it can be enabled trough Vault for desired environments
const generic_sso_enabled = 'false';
const featureFlags = '["GAIA_VOICE"]'

export const environment = {
  production: true,
  glideUsers: glideUsersDomain,
  glideUsersVersion: 'v1/',
  glideCreate: glideComposerDomain,
  glideCreateVersion: 'v1/',
  glideMediaDomain,
  glideMediaCDN,
  glideAuth: glideAuthDomain,
  glideTransmit: glideTransmitDomain,
  glideTransmitVersion: 'v1/',

  // default token expiry time (15 minutes minus 10 seconds)
  authTokenMaxAge: 15 * 60 * 1000 - 10 * 1000,
  // active session is valid for 12 hours
  refreshTokenMaxAge: 12 * 60 * 60 * 1000,
  tokenRefreshThreshold: 3 * 60 * 1000,

  // TODO: these should be a part of settings
  // API keys:
  youTubeApiKey: 'AIzaSyDMUGCHOFmzmpidx1Lu4Ag1K6HGQAgxYAg',
  facebookAppId: '833884667463686',
  facebookAppSecret: '704779ba753b8b1bf846e3e5c65040f5',
  instagramAppId: '833884667463686',
  instagramClientToken: '9e7b87598eb15ae1420d9a4c62a64b33',

  froalaKey: 'mC4B1B1E1D1C2pI1A1A1C7A1F6G4A1I4H4kWa1WDPTf1ZNRGb1OG1g1==',

  envNotes: 'DMG Sandbox',
  version:  '' || '22.6.3',

  mixPanelToken: '67d36be2aa058946efa2639702016db9',
  env: 'sandbox',
  client: 'DMG',

  advancedPublishingVersion: '1.0',
  collectionAdvancedPublishingVersion: '1.0',
  genericSSOEnabled: JSON.parse(`` || generic_sso_enabled),
  featureFlags
};
